import { CurrentPlanRequest, CurrentPlansPage } from '@xplan-pension-switching/common';
import { getAsync, putAsync } from '../../helpers/Api/Api';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

const CACHE_KEY = 'current-plans';

export const getCurrentPlansPageAsync = async (
    entityId: number,
    scenarioId: number
): Promise<{ currentPlansPage: CurrentPlansPage }> => {
    const currentPlansPage: CurrentPlansPage = await getAsync<CurrentPlansPage>(
        `api/clients/${entityId}/scenarios/${scenarioId}/current-plans`
    );
    return { currentPlansPage };
};

export const saveCurrentPlansAsync = async (
    entityId: number,
    scenarioId: number,
    body: CurrentPlanRequest[]
): Promise<void> => {
    await putAsync(`api/clients/${entityId}/scenarios/${scenarioId}/current-plans`, body);
};

export const useCurrentPlansPageQuery = (entityId: number, scenarioId: number) =>
    useSuspenseQuery({
        queryKey: [CACHE_KEY, entityId, scenarioId],
        queryFn: () => getCurrentPlansPageAsync(entityId, scenarioId),
    });

export const useCurrentPlansPageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (args: { entityId: number; scenarioId: number; body: CurrentPlanRequest[] }) =>
            saveCurrentPlansAsync(args.entityId, args.scenarioId, args.body),
        onMutate: variables =>
            queryClient.cancelQueries({ queryKey: [CACHE_KEY, variables.entityId, variables.scenarioId] }),
        onSuccess: (_, variables) =>
            queryClient.invalidateQueries({ queryKey: [CACHE_KEY, variables.entityId, variables.scenarioId] }),
    });
};
