export const useScenarioId = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const scenarioIdParam = searchParams.get('scenarioId');
    if (!scenarioIdParam) return 0;

    const scenarioIdNum = Number(scenarioIdParam);
    if (scenarioIdNum < 1) return 0;

    return scenarioIdNum;
};
