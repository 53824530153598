import '../../styles/IressWealthTheme.css';
import '../../styles/Layout.css';
import { FormDataHandler } from '../../context/FormHandlingContext';
import { IressToasterProvider, IressCol, IressPanel, IressRow, IressStack } from '@iress/ids-components';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import '@iress/themes/build/css/iress-beta-theme-light.css';
import '@iress/themes/global.css';

export const Layout: FC = () => {
    return (
        <div className="uk-wealth-theme iress-beta-theme-light" data-testid={'layout'}>
            <FormDataHandler>
                <IressPanel
                    padding={{
                        x: 'lg',
                        y: 'none',
                    }}
                    background={'page'}
                    className="iff-root"
                >
                    <IressToasterProvider>
                        <IressRow gutter={'none'}>
                            <IressCol>
                                <IressPanel
                                    padding={{
                                        y: 'none',
                                        x: 'lg',
                                    }}
                                    background={'page'}
                                >
                                    <IressStack gutter={'md'}>
                                        <Outlet />
                                    </IressStack>
                                </IressPanel>
                            </IressCol>
                        </IressRow>
                    </IressToasterProvider>
                </IressPanel>
            </FormDataHandler>
        </div>
    );
};
