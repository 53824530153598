import { usePageNavigation } from '../hooks/UsePageNavigation/UsePageNavigation';
import { createContext, FC, MutableRefObject, PropsWithChildren, useRef } from 'react';
import { FormRef } from '@iress/ids-components';
import { GenericForm, Nullable, Page } from '@xplan-pension-switching/common';

export type FormContextType = {
    pages: Page[];
    search: string;
    currentPage: Nullable<Page>;
    nextPage: Nullable<Page>;
    previousPage: Nullable<Page>;
    onFormSubmit: () => void;
    formReference: MutableRefObject<Nullable<FormRef<GenericForm>>>;
};
export const FormHandlingContext = createContext<FormContextType>({} as FormContextType);
export const FormHandlingProvider = FormHandlingContext.Provider;

export const FormDataHandler: FC<PropsWithChildren> = ({ children }) => {
    const formReference = useRef<FormRef<GenericForm>>(null);
    const { pages, search, currentPage, nextPage, previousPage, onFormSubmit } = usePageNavigation(formReference);

    return (
        <FormHandlingProvider
            value={{
                pages,
                currentPage,
                nextPage,
                search,
                previousPage,
                onFormSubmit,
                formReference,
            }}
        >
            {children}
        </FormHandlingProvider>
    );
};
