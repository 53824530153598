import { FC, useContext } from 'react';
import { IressButton, IressCol, IressInline, IressRow, IressText } from '@iress/ids-components';
import { useNavigate } from 'react-router-dom';
import { FormHandlingContext } from '../../context/FormHandlingContext';
import { useScenarioSummaryQuery } from '../../services/ScenariosService/ScenariosService';

export interface IHeaderNav {
    title: string;
    entityId: number;
    scenarioId: number;
}

export const HeaderNavContent: FC<IHeaderNav> = (props: IHeaderNav) => {
    const navigate = useNavigate();
    const { search, previousPage, nextPage, onFormSubmit } = useContext(FormHandlingContext);
    const { entityId, scenarioId, title } = props;
    const { data } = useScenarioSummaryQuery(entityId, scenarioId);
    const { owner } = data.scenarioSummary;

    return (
        <IressRow>
            <IressCol span={10}>
                <IressInline horizontalAlign={'left'} gutter={'sm'} noWrap>
                    <IressText element={'h1'} variant={'bold'}>{`${title}:`}</IressText>
                    <IressText element={'h1'}>{`${owner?.lastname}, ${owner?.firstname}`}</IressText>
                </IressInline>
            </IressCol>
            <IressCol span={2}>
                <IressInline horizontalAlign={'right'} verticalAlign={'bottom'} gutter={'sm'} noWrap>
                    <IressButton
                        id={'btnBack'}
                        mode={'secondary'}
                        data-testid={'btnBack'}
                        onClick={() => {
                            let navigateTo = {};
                            if (!previousPage) {
                                if (entityId) {
                                    navigateTo = {
                                        pathname: '/',
                                        search: `?entityid=${entityId}`,
                                    };
                                } else {
                                    navigateTo = { pathname: '/' };
                                }
                            } else {
                                navigateTo = {
                                    pathname: `/${previousPage.key}`,
                                    search: `${search}`,
                                };
                            }
                            navigate(navigateTo);
                        }}
                    >
                        Back
                    </IressButton>
                    <IressButton
                        mode={'primary'}
                        type={'submit'}
                        data-testid={'btnSaveAndContinue'}
                        onClick={onFormSubmit}
                    >
                        {nextPage ? 'Save & continue' : 'Save & exit'}
                    </IressButton>
                </IressInline>
            </IressCol>
        </IressRow>
    );
};
