import { FC } from 'react';
import { IressCol, IressInline, IressPanel, IressRow, IressText } from '@iress/ids-components';
import { Client, ClientType } from '@xplan-pension-switching/common';

export interface IScenarioHeader {
    clients: Client[];
}

export const ScenarioHeader: FC<IScenarioHeader> = (props: IScenarioHeader) => {
    const client = props.clients.find(c => c.clientType === ClientType.Client);
    const partner = props.clients.find(c => c.clientType === ClientType.Partner);

    let clientNames = `${client?.lastname}, ${client?.firstname}`;
    if (partner && partner !== client) {
        clientNames = `${clientNames} & ${partner?.lastname}, ${partner.firstname}`;
    }

    return (
        <IressPanel background={'transparent'} padding={{ t: 'md', x: 'none' }}>
            <IressRow>
                <IressCol span={10}>
                    <IressInline horizontalAlign={'left'} gutter={'sm'} noWrap>
                        <IressText element={'h1'} variant={'bold'}>
                            Pension switching:
                        </IressText>
                        <IressText element={'h1'} data-testid={'txtClients'}>
                            {clientNames}
                        </IressText>
                    </IressInline>
                </IressCol>
            </IressRow>
        </IressPanel>
    );
};
