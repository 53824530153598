declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- because it should be interface
    interface Window {
        deployConfig?: { bffURL?: string };
    }
}

export const urlResolve = (): string => {
    if (window.deployConfig?.bffURL) {
        return window.deployConfig.bffURL;
    }
    return window.location.origin;
};
