import { useEffect } from 'react';
import { useToaster } from '@iress/ids-components';
import { setToaster } from '../../redux/Toaster/ToasterReducer';
import { useAppDispatch, useAppSelector } from '../../redux/store';

export const useToasterUpdater = (): void => {
    const toaster = useToaster();
    const dispatch = useAppDispatch();
    const toasterData = useAppSelector(state => state.toaster.toasterData);

    useEffect(() => {
        if (toasterData) {
            toaster[toasterData.status]({ heading: toasterData.heading, children: toasterData.message });
            dispatch(setToaster(null));
        }
    }, [dispatch, toaster, toasterData]);
};
