import { FC, useState } from 'react';
import { IressButton, IressCol, IressIcon, IressInline, IressRow, IressText } from '@iress/ids-components';
import { Nullable } from '@xplan-pension-switching/common';

export type ScenarioModalFooterProps = {
    hasFunds: boolean;
    setShow: (show: boolean) => void;
    onSubmit: () => Promise<{ success: boolean; errorMessage: string }>;
};

export const ScenarioModalFooter: FC<ScenarioModalFooterProps> = props => {
    const { hasFunds, setShow, onSubmit } = props;
    const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
    return hasFunds ? (
        <IressRow gutter={'md'} verticalAlign={'middle'}>
            <IressCol>
                {errorMessage ? (
                    <IressText mode={'danger'} element={'h5'} className={'error'}>
                        <IressIcon name={'ban'} />
                        <span>{errorMessage}</span>
                    </IressText>
                ) : null}
            </IressCol>
            <IressCol>
                <IressInline gutter={'md'} horizontalAlign={'right'}>
                    <IressButton
                        data-testid={'scenario-modal-btn-back'}
                        mode={'secondary'}
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </IressButton>
                    <IressButton
                        data-testid={'scenario-modal-btn-continue'}
                        mode={'primary'}
                        type={'button'}
                        onClick={async () => {
                            const { success, errorMessage } = await onSubmit();
                            if (success) {
                                setShow(false);
                                return;
                            }

                            if (errorMessage) {
                                setErrorMessage(errorMessage);
                            }
                        }}
                    >
                        Continue
                    </IressButton>
                </IressInline>
            </IressCol>
        </IressRow>
    ) : (
        <IressInline gutter={'md'} horizontalAlign={'right'}>
            <IressButton data-testid={'scenario-modal-btn-ok'} mode={'primary'} onClick={() => setShow(false)}>
                Ok
            </IressButton>
        </IressInline>
    );
};
