import { FC, memo, RefObject, useContext } from 'react';
import { CurrentValues } from './CurrentValues/CurrentValues';
import { FormRef, IressForm, IressStack, IressText } from '@iress/ids-components';
import { CurrentPlanProjectionDetailsValues, CurrentPlanProjectionDetailsProps } from '@xplan-pension-switching/common';
import {
    useCancelCurrentPlanProjectionDetailsQuery,
    useCurrentPlanProjectionDetailsMutation,
    useCurrentPlanProjectionDetailsPageQuery,
} from '../../services/CurrentPlanProjectionDetailsService/CurrentPlanProjectionDetailsService';
import { useNavigate } from 'react-router-dom';
import { FormHandlingContext } from '../../context/FormHandlingContext';
import { useReferenceDataQuery } from '../../services/ReferenceDataService/ReferenceDataService';
import { ProjectedGrowthRates } from './ProjectedGrowthRates/ProjectedGrowthRates';
import { OngoingContributions } from './OngoingContributions/OngoingContributions';

export const CurrentPlanProjectionDetailsForm = IressForm<CurrentPlanProjectionDetailsValues>;

const CurrentPlanProjectionDetailsFormContent: FC<CurrentPlanProjectionDetailsProps> = props => {
    const { entityId, scenarioId, projectionId } = props;
    const navigate = useNavigate();
    const mutation = useCurrentPlanProjectionDetailsMutation();
    const cancelQuery = useCancelCurrentPlanProjectionDetailsQuery(entityId, scenarioId, projectionId);
    const { formReference } = useContext(FormHandlingContext);
    const { data } = useCurrentPlanProjectionDetailsPageQuery(entityId, scenarioId, projectionId);
    const { currentValues, ongoingContributions, projectedGrowthRates } = data.currentPlanProjectionDetailsPage.details;
    const refDataQueryResponse = useReferenceDataQuery();
    const referenceData = refDataQueryResponse.data.referenceData;

    const formDefaultValues: CurrentPlanProjectionDetailsValues = {
        ...currentValues,
        ...ongoingContributions,
        ...projectedGrowthRates,
    };

    const onSubmit = async (values: CurrentPlanProjectionDetailsValues) => {
        const toUpdate = {
            details: {
                currentValues: {
                    currentValueAmount: Number(values.currentValueAmount),
                    transferValueAmount: Number(values.transferValueAmount),
                    asAtDate: values.asAtDate,
                },
                ongoingContributions: {
                    frequency: values.frequency,
                    grossPremium: values.grossPremium,
                },
                projectedGrowthRates: {
                    growthRateBasis: values.growthRateBasis,
                    growthRateLow: values.growthRateLow,
                    growthRateMid: values.growthRateMid,
                    growthRateHigh: values.growthRateHigh,
                },
            },
        };

        await mutation.mutateAsync(
            { entityId, scenarioId, projectionId, ...toUpdate },
            {
                onSuccess: () => {
                    cancelQuery();
                    navigate({
                        pathname: `/current-plan-projections`,
                        search: `?entityid=${entityId}&scenarioId=${scenarioId}`,
                    });
                },
            }
        );
    };

    return (
        <CurrentPlanProjectionDetailsForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            ref={formReference as RefObject<FormRef<CurrentPlanProjectionDetailsValues>>}
            mode={'onBlur'}
            data-testid={'current-plan-projection-details-form'}
        >
            <IressStack gutter={'md'}>
                <IressText element={'h2'} variant={'bold'}>
                    {data.currentPlanProjectionDetailsPage.name}
                </IressText>
                <CurrentValues />
                <OngoingContributions ongoingContributions={ongoingContributions} />
                <ProjectedGrowthRates projectedGrowthRates={projectedGrowthRates} referenceData={referenceData} />
            </IressStack>
        </CurrentPlanProjectionDetailsForm>
    );
};

export const MemoizedCurrentPlanProjectionDetailsFormContent = memo(CurrentPlanProjectionDetailsFormContent);
