import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { ScenarioActions } from './ScenarioActions';
import { ScenarioLinkRenderer } from './ScenarioLinkRenderer';
import { caseFormatter, dateTimeFormatter, reportFormatter } from './ScenariosTableFormatters';
import { Scenario } from '@xplan-pension-switching/common';

export const scenariosTableColDefs: ColDef[] = [
    {
        field: 'scenarioActions',
        headerName: '',
        cellRenderer: ScenarioActions,
        sortable: false,
        minWidth: 120,
        maxWidth: 120,
    },
    {
        field: 'scenarioName',
        headerName: 'Scenario',
        cellRenderer: ScenarioLinkRenderer,
        sortable: false,
        flex: 1.5,
        minWidth: 198,
    },
    {
        field: 'ownerClientType',
        headerName: 'Owner',
        sortable: false,
        minWidth: 80,
        maxWidth: 80,
    },
    {
        field: 'case',
        headerName: 'Case',
        valueFormatter: caseFormatter,
        sortable: false,
        flex: 1.5,
        minWidth: 198,
    },
    {
        field: 'reports',
        headerName: 'Reports',
        valueFormatter: reportFormatter,
        sortable: false,
        minWidth: 85,
        maxWidth: 85,
    },
    {
        field: 'createdBy',
        headerName: 'Created by',
        valueFormatter: (params: ValueFormatterParams) => {
            const scenario = params.data as Scenario;
            return dateTimeFormatter(scenario.createdBy, scenario.createdDate);
        },
        sortable: false,
        flex: 1.5,
        minWidth: 198,
    },
    {
        field: 'lastModified',
        headerName: 'Last modified',
        valueFormatter: (params: ValueFormatterParams) => {
            const scenario = params.data as Scenario;
            return dateTimeFormatter(scenario.modifiedBy, scenario.modifiedDate);
        },
        sortable: false,
        flex: 1.5,
        minWidth: 198,
    },
];
