import React from 'react';
import ReactDOM from 'react-dom/client';
import { RoutingLoader } from './routing/RoutingLoader';
import { Provider } from 'react-redux';
import { appStore } from './redux/store';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { AG_GRID_LICENSE_KEY } from '@iress/ag-grid-license-key';
import '@iress/ids-components/dist/style.css';
import '@iress/themes/build/css/iress-theme-light.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

export const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={appStore}>
            <QueryClientProvider client={queryClient}>
                <RoutingLoader />
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);
