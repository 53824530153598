import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { getAsync, putAsync } from '../../helpers/Api/Api';
import { CurrentPlanProjectionDetails, CurrentPlanProjectionDetailsPage } from '@xplan-pension-switching/common';
import { format } from 'date-fns';

const CACHE_KEY = 'current-plan-projection-details';

export const getCurrentPlanProjectionDetailsPageAsync = async (
    entityId: number,
    scenarioId: number,
    projectionId: number
): Promise<{ currentPlanProjectionDetailsPage: CurrentPlanProjectionDetailsPage }> => {
    const currentPlanProjectionDetailsPage: CurrentPlanProjectionDetailsPage =
        await getAsync<CurrentPlanProjectionDetailsPage>(
            `api/clients/${entityId}/scenarios/${scenarioId}/current-plan-projection-details/${projectionId}`
        );

    // We want to keep using DateTime objects in the BFF but the Date input only
    // wants yyyy-MM-dd formatted strings
    if (currentPlanProjectionDetailsPage?.details?.currentValues?.asAtDate != null) {
        currentPlanProjectionDetailsPage.details.currentValues.asAtDate = format(
            currentPlanProjectionDetailsPage.details.currentValues.asAtDate,
            'yyyy-MM-dd'
        );
    }

    return { currentPlanProjectionDetailsPage };
};

export const useCurrentPlanProjectionDetailsPageQuery = (entityId: number, scenarioId: number, currentPlanId: number) =>
    useSuspenseQuery({
        queryKey: [CACHE_KEY, entityId, scenarioId, currentPlanId],
        queryFn: () => getCurrentPlanProjectionDetailsPageAsync(entityId, scenarioId, currentPlanId),
    });

export const putCurrentPlanProjectionDetailsAsync = async (
    entityId: number,
    scenarioId: number,
    projectionId: number,
    details: CurrentPlanProjectionDetails
): Promise<{ currentPlanProjectionDetails: CurrentPlanProjectionDetails }> => {
    const currentPlanProjectionDetails: CurrentPlanProjectionDetails = await putAsync(
        `api/clients/${entityId}/scenarios/${scenarioId}/current-plan-projection-details/${projectionId}`,
        details
    );
    return { currentPlanProjectionDetails };
};

export const useCurrentPlanProjectionDetailsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (args: {
            entityId: number;
            scenarioId: number;
            projectionId: number;
            details: CurrentPlanProjectionDetails;
        }) => putCurrentPlanProjectionDetailsAsync(args.entityId, args.scenarioId, args.projectionId, args.details),
        onMutate: variables =>
            queryClient.cancelQueries({
                queryKey: [CACHE_KEY, variables.entityId, variables.scenarioId, variables.projectionId],
            }),
    });
};

export const useCancelCurrentPlanProjectionDetailsQuery = (
    entityId: number,
    scenarioId: number,
    projectionId: number
) => {
    const queryClient = useQueryClient();

    return async (): Promise<void> => {
        await queryClient.invalidateQueries({
            queryKey: [CACHE_KEY, entityId, scenarioId, projectionId],
        });
    };
};
