import { Component, PropsWithChildren, ReactElement, ReactNode } from 'react';

// eslint-disable-next-line react/require-optimization -- noise
export class ErrorBoundary extends Component<PropsWithChildren<{ fallback: ReactElement }>> {
    public fallback: ReactElement;
    // eslint-disable-next-line react/state-in-constructor -- no it doesnt
    public readonly state: { hasError: boolean } = { hasError: false };
    public constructor(properties: { children: ReactNode; fallback: ReactElement }) {
        super(properties);
        this.state = { hasError: false };
        this.fallback = properties.fallback;
    }

    // eslint-disable-next-line react/sort-comp -- noise
    public static getDerivedStateFromError(): { hasError: boolean } {
        return { hasError: true };
    }

    // eslint-disable-next-line class-methods-use-this -- noise
    public componentDidCatch(error: Error, errorInfo: unknown): void {
        // eslint-disable-next-line no-console -- required for logging out ui errors
        console.error(error, errorInfo);
    }

    public render(): ReactNode {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return this.fallback;
        }

        return children;
    }
}
