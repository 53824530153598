import { FC } from 'react';
import {
    GrowthRateBasisType,
    Nullable,
    ProjectedGrowthRateValues,
    ReferenceData,
} from '@xplan-pension-switching/common';
import {
    IressDivider,
    IressForm,
    IressFormField,
    IressPanel,
    IressSelect,
    IressText,
    LabelValue,
} from '@iress/ids-components';
import { IndustryStandardTable } from './IndustryStandardTable';
import { FundSpecificNominalTable } from './FundSpecificNominalTable';
import { FundSpecificInflationTable } from './FundSpecificInflationTable';

export const ProjectedGrowthRates: FC<{
    projectedGrowthRates: Nullable<ProjectedGrowthRateValues>;
    referenceData: ReferenceData;
}> = ({ projectedGrowthRates, referenceData }) => {
    const growthValueTypeOptions: LabelValue[] = [
        {
            label: 'Industry standard',
            value: GrowthRateBasisType.IndustryStandard,
        },
        {
            label: 'Fund specific: nominal',
            value: GrowthRateBasisType.FundSpecificNominal,
        },
        {
            label: 'Fund specific: inflation',
            value: GrowthRateBasisType.FundSpecificInflation,
        },
    ];

    const growthRateBasisDefaultValue = projectedGrowthRates
        ? projectedGrowthRates.growthRateBasis
        : GrowthRateBasisType.IndustryStandard;

    const growthRateBasisFormValue = IressForm.useWatch({
        name: 'growthRateBasis',
        defaultValue: growthRateBasisDefaultValue,
    });

    return (
        <>
            <IressText element={'h2'}>Projected growth rates</IressText>
            <IressPanel>
                <IressFormField
                    label={'Growth rate type'}
                    name={'growthRateBasis'}
                    defaultValue={growthRateBasisDefaultValue}
                    rules={{ required: true }}
                    render={controlledProps => (
                        <IressSelect data-testid={'growthValueType'} width={'25perc'} {...controlledProps}>
                            {growthValueTypeOptions.map(option => (
                                <option key={String(option.value)} value={String(option.value)}>
                                    {option.label}
                                </option>
                            ))}
                        </IressSelect>
                    )}
                />
                <IressDivider />
                {Number(growthRateBasisFormValue) === GrowthRateBasisType.IndustryStandard ? (
                    <IndustryStandardTable referenceData={referenceData} />
                ) : null}
                {Number(growthRateBasisFormValue) === GrowthRateBasisType.FundSpecificNominal ? (
                    <FundSpecificNominalTable />
                ) : null}
                {Number(growthRateBasisFormValue) === GrowthRateBasisType.FundSpecificInflation ? (
                    <FundSpecificInflationTable />
                ) : null}
            </IressPanel>
        </>
    );
};
