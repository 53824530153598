import { FC } from 'react';
import { IressInput, IressPanel, IressStack, IressInline, IressText, IressFormField } from '@iress/ids-components';
import { todayIsoDate } from '../../helpers/Date/Date';
import { formatDate } from 'date-fns';

export const CurrentValues: FC = () => {
    const asAtDateFormatter = (dateTime: string) => {
        try {
            return formatDate(dateTime, 'dd/MM/yyyy');
        } catch (e: unknown) {
            return '';
        }
    };

    return (
        <>
            <IressText element={'h2'}>Current values</IressText>
            <IressPanel padding={'md'} background={'default'}>
                <IressStack gutter={'md'}>
                    <IressInline gutter={'xl'} horizontalAlign={'left'} verticalAlign={'bottom'}>
                        <IressFormField
                            label="Current value"
                            name={'currentValueAmount'}
                            render={controlledProps => (
                                <IressInput
                                    data-testid={'txtCurrentValue'}
                                    type={'number'}
                                    required={true}
                                    width={'12'}
                                    min={'0.01'}
                                    append={<IressText>GBP</IressText>}
                                    {...controlledProps}
                                />
                            )}
                        ></IressFormField>
                        <IressFormField
                            label="Transfer value"
                            name={'transferValueAmount'}
                            render={controlledProps => (
                                <IressInput
                                    data-testid={'txtTransferValue'}
                                    type={'number'}
                                    required={true}
                                    width={'12'}
                                    min={'0.01'}
                                    append={<IressText>GBP</IressText>}
                                    {...controlledProps}
                                />
                            )}
                        ></IressFormField>
                        <IressFormField
                            label="As at date"
                            name={'asAtDate'}
                            render={controlledProps => (
                                <IressInput
                                    data-testid={'dtAsAtDate'}
                                    formatter={dt => asAtDateFormatter(dt)}
                                    type={'date'}
                                    required={true}
                                    width={'12'}
                                    min={'1900-01-01'}
                                    max={todayIsoDate()}
                                    {...controlledProps}
                                />
                            )}
                        ></IressFormField>
                    </IressInline>
                </IressStack>
            </IressPanel>
        </>
    );
};
