import { FC } from 'react';
import { FrequencyType, OngoingContributionValues } from '@xplan-pension-switching/common';
import {
    IressFormField,
    IressInline,
    IressInput,
    IressPanel,
    IressRadio,
    IressRadioGroup,
    IressStack,
    IressText,
} from '@iress/ids-components';

export const OngoingContributions: FC<{
    ongoingContributions: OngoingContributionValues;
}> = ({ ongoingContributions }) => {
    // TODO: Temporary defaulting as OngoingContributions is not currently returned from BFF. Remove when NEP-197 is complete
    const defaultOngoingContributions: OngoingContributionValues = {
        frequency: FrequencyType.Monthly,
        grossPremium: 0,
    };
    const tempOngoingContributions = ongoingContributions ?? defaultOngoingContributions;

    return (
        <>
            <IressText element={'h2'}>Ongoing contributions</IressText>
            <IressPanel>
                <IressStack gutter={'md'}>
                    <IressFormField
                        label={'Frequency'}
                        name={'frequency'}
                        defaultValue={tempOngoingContributions.frequency}
                        render={controlledProps => (
                            <IressRadioGroup layout={'inline'} {...controlledProps}>
                                <IressRadio value={FrequencyType.Annually}>Annually</IressRadio>
                                <IressRadio value={FrequencyType.Monthly}>Monthly</IressRadio>
                            </IressRadioGroup>
                        )}
                    />
                    <IressInline gutter={'md'}>
                        <IressFormField
                            label={'Gross premium'}
                            name={'grossPremium'}
                            defaultValue={tempOngoingContributions.grossPremium}
                            rules={{
                                required: 'Gross premium is required',
                                min: 0,
                            }}
                            render={controlledProps => (
                                <IressInput
                                    type={'number'}
                                    width={'12'}
                                    append={<IressText>GBP</IressText>}
                                    {...controlledProps}
                                />
                            )}
                        />
                    </IressInline>
                </IressStack>
            </IressPanel>
        </>
    );
};
