import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { IressButton } from '@iress/ids-components';
import { useEntityId } from '../../../hooks/UseEntityId/UseEntityId';
import { Scenario } from '@xplan-pension-switching/common';

export const ScenarioLinkRenderer: FC<ICellRendererParams<Scenario>> = params => {
    const scenario: Scenario = params.data as Scenario;
    const entityId = useEntityId();
    const navigate = useNavigate();

    const handleScenarioEdit = () => {
        navigate({
            pathname: `/current-plans`,
            search: `?entityid=${entityId}&scenarioId=${scenario.id}`,
        });
    };

    return (
        <IressButton mode={'link'} onClick={handleScenarioEdit}>
            {scenario.scenarioName}
        </IressButton>
    );
};
