import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout } from '../components';
import { CurrentPlanProjections, CurrentPlanProjectionDetails, CurrentPlans, Scenarios } from '../pages';

export const getBasename = (path: string): string => {
    if (path.startsWith('/cloud_app/')) {
        const parts = path.split('/');
        const appNamePart = 2;
        return parts.length > 1 ? `/cloud_app/${parts[appNamePart]}/` : '/';
    } else {
        return '/';
    }
};

export const createRoutes = () => {
    return {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Scenarios />,
            },
            {
                path: '/current-plans',
                element: <CurrentPlans />,
            },
            {
                path: '/current-plan-projections',
                element: <CurrentPlanProjections />,
            },
            {
                path: '/current-plan-projections/details',
                element: <CurrentPlanProjectionDetails />,
            },
        ],
    };
};

export const RoutingLoader: FC = () => {
    const router = createBrowserRouter([createRoutes()], {
        basename: getBasename(window.location.pathname),
    });
    return <RouterProvider router={router} />;
};
