import { FC, MutableRefObject, ReactElement, useState } from 'react';
import { LoadingAndErrorHandler } from '../../LoadingAndErrorHandler/LoadingAndErrorHandler';
import { ScenarioModalContent } from './ScenarioModalContent';
import { LoadingPlaceholder } from '../../LoadingPlaceholder/LoadingPlaceholder';
import { PageErrorWithReload } from '../../PageErrorWithReload/PageErrorWithReload';
import { IressModal, IressPanel } from '@iress/ids-components';
import { CreateScenarioRequest } from '@xplan-pension-switching/common';

export interface ScenarioModalProps {
    request: CreateScenarioRequest;
    show: boolean;
    setShow: (show: boolean) => void;
    setHeader?: (headerContent: string | ReactElement) => void;
    setFooter?: (footerContent: string | ReactElement) => void;
    container?: MutableRefObject<null>;
}

export const ScenarioModal: FC<ScenarioModalProps> = props => {
    const { request, show, setShow, container } = props;
    const [header, setHeader] = useState<string | ReactElement>(<IressPanel background={'transparent'}></IressPanel>);
    const [footer, setFooter] = useState<string | ReactElement>(<IressPanel background={'transparent'}></IressPanel>);

    return (
        <IressModal
            size={'lg'}
            data-testid={`scenario-modal`}
            show={show}
            onShowChange={setShow}
            heading={header}
            footer={footer}
            container={container}
        >
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'150'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            >
                <ScenarioModalContent
                    request={request}
                    show={show}
                    setShow={setShow}
                    setHeader={setHeader}
                    setFooter={setFooter}
                />
            </LoadingAndErrorHandler>
        </IressModal>
    );
};
