import axios from 'axios';
import { urlResolve } from '../UrlResolve/UrlResolve';
import { appStore } from '../../redux/store';
import { setToaster } from '../../redux/Toaster/ToasterReducer';
import { Nullable, ToasterDefaultStatusHeading, ToasterStatus } from '@xplan-pension-switching/common';

export interface QueryStringParams {
    [key: string]: string | number | boolean;
}

export async function getAsync<TResponse>(
    path: string,
    params: Nullable<QueryStringParams> = null
): Promise<TResponse> {
    const response = await axios.get<TResponse>(`${urlResolve()}/${path}`, params || undefined).catch(function (error) {
        appStore.dispatch(
            setToaster({
                heading: ToasterDefaultStatusHeading.Error,
                message: (error as Error).message,
                status: ToasterStatus.Error,
            })
        );
        throw error;
    });
    return response.data;
}

export async function postAsync<TResponse>(path: string, data: object): Promise<TResponse> {
    const response = await axios.post<TResponse>(`${urlResolve()}/${path}`, data).catch(function (error) {
        appStore.dispatch(
            setToaster({
                heading: ToasterDefaultStatusHeading.Error,
                message: (error as Error).message,
                status: ToasterStatus.Error,
            })
        );
        throw error;
    });
    return response.data;
}

export async function putAsync<TResponse>(path: string, data: object): Promise<TResponse> {
    const response = await axios.put<TResponse>(`${urlResolve()}/${path}`, data).catch(function (error) {
        appStore.dispatch(
            setToaster({
                heading: ToasterDefaultStatusHeading.Error,
                message: (error as Error).message,
                status: ToasterStatus.Error,
            })
        );
        throw error;
    });
    return response.data;
}
