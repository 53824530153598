import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload, Screen } from '../../components';
import { IressPanel, IressText } from '@iress/ids-components';
import { FC } from 'react';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { useScenarioId } from '../../hooks/UseScenarioId/UseScenarioId';
import { MemoizedCurrentPlansFormContent } from '../../components/CurrentPlan/CurrentPlansFormContent';

export const CurrentPlans: FC = () => {
    const entityId = useEntityId();
    const scenarioId = useScenarioId();

    return (
        <Screen title={'Current plans'}>
            <IressPanel padding={'none'} background={'transparent'}>
                <IressText element={'h2'}>
                    Select the plans you wish to include in the pension switching analysis
                </IressText>
                <LoadingAndErrorHandler
                    LoadingComponentFallback={<LoadingPlaceholder height={'150'} />}
                    ErrorComponentFallback={<PageErrorWithReload />}
                >
                    <MemoizedCurrentPlansFormContent entityId={entityId} scenarioId={scenarioId} />
                </LoadingAndErrorHandler>
            </IressPanel>
        </Screen>
    );
};
