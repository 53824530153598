import { useSuspenseQuery } from '@tanstack/react-query';
import { getAsync } from '../../helpers/Api/Api';
import { CurrentPlanProjectionDetailsPage } from '@xplan-pension-switching/common';

const CACHE_KEY = 'current-plan-projection-details';

export const getCurrentPlanProjectionDetailsPageAsync = async (
    entityId: number,
    scenarioId: number,
    currentPlanId: number
): Promise<{ currentPlanProjectionDetailsPage: CurrentPlanProjectionDetailsPage }> => {
    const currentPlanProjectionDetailsPage: CurrentPlanProjectionDetailsPage =
        await getAsync<CurrentPlanProjectionDetailsPage>(
            `api/clients/${entityId}/scenarios/${scenarioId}/current-plan-projection-details/${currentPlanId}`
        );
    return { currentPlanProjectionDetailsPage };
};

export const useCurrentPlanProjectionDetailsPageQuery = (entityId: number, scenarioId: number, currentPlanId: number) =>
    useSuspenseQuery({
        queryKey: [CACHE_KEY, entityId, scenarioId, currentPlanId],
        queryFn: () => getCurrentPlanProjectionDetailsPageAsync(entityId, scenarioId, currentPlanId),
    });
