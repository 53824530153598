import { FC } from 'react';
import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload, Screen } from '../../components';
import { MemoizedCurrentPlanProjectionDetailsFormContent } from '../../components/CurrentPlanProjectionDetails/CurrentPlanProjectionDetailsFormContent';

export const CurrentPlanProjectionDetails: FC = () => {
    return (
        <Screen title={'Current Plan Projection Details'}>
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'450'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            >
                <MemoizedCurrentPlanProjectionDetailsFormContent />
            </LoadingAndErrorHandler>
        </Screen>
    );
};
