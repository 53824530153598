import { FC, memo } from 'react';
import { useScenariosPageQuery } from '../../services/ScenariosService/ScenariosService';
import { IressPanel, IressStack, IressText } from '@iress/ids-components';
import { CreateScenario } from './CreateScenario/CreateScenario';
import { ScenarioHeader } from './ScenarioHeader';
import { ScenariosTable } from './ScenarioTable/ScenariosTable';

const ScenarioFormContent: FC<{ entityId: number }> = props => {
    const { entityId } = props;
    const { data } = useScenariosPageQuery(entityId);
    const { cases, scenarios, clients } = data.scenariosPage;

    return (
        <>
            <ScenarioHeader clients={clients} />
            <IressStack gutter={'md'}>
                <IressPanel padding={'none'} background={'transparent'}>
                    <IressText element={'h2'}>Create a scenario</IressText>
                    <CreateScenario cases={cases} clients={clients} />
                </IressPanel>
                <IressPanel padding={{ t: 'md', x: 'none' }} background={'transparent'}>
                    <IressText element={'h2'}>Existing scenarios</IressText>
                    <ScenariosTable scenarios={scenarios} />
                </IressPanel>
            </IressStack>
        </>
    );
};

export const MemoizedScenarioFormContent = memo(ScenarioFormContent);
