import { IressRow, IressCol, IressAlert } from '@iress/ids-components';
import { PropsWithChildren, FC } from 'react';

export const PageErrorAlert: FC<PropsWithChildren<{ heading: string; testId?: string }>> = ({
    heading,
    testId,
    children,
}) => (
    <IressRow gutter={'lg'}>
        <IressCol span={{ md: 6 }}>
            <IressAlert status={'danger'} heading={heading} data-testid={testId ?? undefined}>
                {children}
            </IressAlert>
        </IressCol>
    </IressRow>
);
