import { IressAlert, IressCheckbox, IressPanel, IressStack, IressTable, TableColumn } from '@iress/ids-components';
import { FC, useEffect, useState } from 'react';
import { RetirementFund } from '@xplan-pension-switching/common';

import '../../../styles/PlanSelectionModal.css';

export type RetirementFundRow = RetirementFund & { isSelected: boolean };

export const PlanSelection: FC<{
    plans: RetirementFund[];
    onSelectionChanged: (retirementFundIds: number[]) => void;
}> = props => {
    const { plans, onSelectionChanged } = props;
    const [rows, setRows] = useState<RetirementFundRow[]>(plans.map(p => ({ ...p, isSelected: true })));

    const onChange = (id: number, isSelected: boolean) => {
        setRows(rows.map(r => (r.id === id ? { ...r, isSelected } : r)));
    };

    useEffect(() => {
        const retirementFundIds = rows.filter(r => r.isSelected).map(r => r.id);
        onSelectionChanged(retirementFundIds);
    }, [rows]);

    const columnDefs: TableColumn<RetirementFundRow>[] = [
        {
            key: 'isSelected',
            label: '',
            divider: false,
            width: '5%',
            align: 'center',
            format: (value, row) => {
                return (
                    <IressCheckbox
                        data-testid={`plan-select_${row?.id}`}
                        value={value}
                        checked={value}
                        onChange={e => onChange(row!.id, e.target.checked)}
                    />
                );
            },
        },
        {
            key: 'name',
            label: 'Plan',
            divider: false,
            width: '45%',
        },
        {
            key: 'type',
            label: 'Type',
            divider: false,
            width: '30%',
        },
        {
            key: 'fundValue',
            label: 'Value (GBP)',
            divider: false,
            width: '20%',
            format: 'currency',
            currencyCode: '',
            align: 'right',
        },
    ];
    return (
        <IressStack gutter={'md'}>
            <IressAlert className={'info'} status={'info'}>
                You cannot change selected plans once a scenario is created
            </IressAlert>
            <IressPanel>
                <IressTable
                    className={'plan-selection-table'}
                    caption={'Select plans'}
                    hiddenCaption
                    columns={columnDefs}
                    rows={rows}
                />
            </IressPanel>
        </IressStack>
    );
};
