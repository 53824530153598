export const useEntityId = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const entityIdParam = searchParams.get('entityid');
    if (!entityIdParam) return 0;

    const entityIdNum = Number(entityIdParam);
    if (entityIdNum < 1) return 0;

    return entityIdNum;
};
