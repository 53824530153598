import { ColDef } from 'ag-grid-community';
import { currentPlanValueFormatter } from './CurrentPlansTableFormatters';

export const currentPlansTableColDefs: ColDef[] = [
    {
        field: 'isSelected',
        headerName: '',
        cellClass: 'current-plans-cell',
        sortable: false,
        minWidth: 60,
        maxWidth: 60,
        editable: true,
    },
    {
        field: 'name',
        headerName: 'Plan',
        sortable: false,
        flex: 1.5,
        minWidth: 198,
    },
    {
        field: 'type',
        headerName: 'Type',
        sortable: false,
        flex: 1.5,
        minWidth: 198,
    },
    {
        field: 'planValue',
        headerName: 'Value (GBP)',
        valueFormatter: currentPlanValueFormatter,
        cellStyle: { justifyContent: 'right' },
        type: 'rightAligned',
        sortable: false,
        flex: 2,
        minWidth: 198,
    },
];
