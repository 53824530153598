import { FC, memo, RefObject, useContext, useState } from 'react';
import {
    useCurrentPlansPageMutation,
    useCurrentPlansPageQuery,
} from '../../services/CurrentPlansService/CurrentPlansService';
import { FormRef, IressAlert, IressText } from '@iress/ids-components';
import { CurrentPlansTable } from './CurrentPlansTable';
import { CurrentPlansFields, CurrentPlansForm } from './CurrentPlansForm';
import { FormHandlingContext } from '../../context/FormHandlingContext';
import { useNavigate } from 'react-router-dom';
import { ScenarioProps } from '@xplan-pension-switching/common';

const CurrentPlansFormContent: FC<ScenarioProps> = props => {
    const navigate = useNavigate();
    const { formReference } = useContext(FormHandlingContext);
    const { entityId, scenarioId } = props;
    const { data } = useCurrentPlansPageQuery(entityId, scenarioId);
    const mutation = useCurrentPlansPageMutation();
    const [showAlert, setShowAlert] = useState(false);

    const onSubmit = async () => {
        const hasSelected = data.currentPlansPage.currentPlans.some(p => p.isSelected);
        setShowAlert(!hasSelected);

        if (!hasSelected) return;

        await mutation.mutateAsync({ entityId, scenarioId, body: data.currentPlansPage.currentPlans }).then(() => {
            navigate({
                pathname: `/current-plan-projections`,
                search: `?entityid=${entityId}&scenarioId=${scenarioId}`,
            });
        });
    };

    return (
        <CurrentPlansForm
            data-testid={'current-plans-form'}
            ref={formReference as RefObject<FormRef<CurrentPlansFields>>}
            mode={'onBlur'}
            onSubmit={onSubmit}
        >
            {showAlert ? (
                <IressAlert
                    status={'danger'}
                    heading={<h2>No plans selected</h2>}
                    className={'iress-mb--lg'}
                    data-testid={'key-details-error-summary'}
                >
                    <IressText>Select plan(s) to proceed</IressText>
                </IressAlert>
            ) : null}
            <CurrentPlansTable currentPlans={data.currentPlansPage.currentPlans} />
        </CurrentPlansForm>
    );
};

export const MemoizedCurrentPlansFormContent = memo(CurrentPlansFormContent);
