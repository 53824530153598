import { getAsync, postAsync } from '../../helpers/Api/Api';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { Nullable, Scenario, ScenariosPage, ScenarioSummary } from '@xplan-pension-switching/common';

const CACHE_KEY = 'scenarios';

export const createScenarioAsync = async (
    clientId: number,
    ownerId: number,
    scenarioName: string,
    caseId: Nullable<number>
): Promise<{ scenario: Scenario }> => {
    const scenario: Scenario = await postAsync(`api/clients/${clientId}/scenarios`, {
        ownerId,
        scenarioName,
        caseId,
    });
    return { scenario };
};

export const getScenariosPageAsync = async (entityId: number): Promise<{ scenariosPage: ScenariosPage }> => {
    const scenariosPage: ScenariosPage = await getAsync<ScenariosPage>(`api/clients/${entityId}/scenarios`);
    return { scenariosPage };
};

export const getScenarioSummaryAsync = async (
    entityId: number,
    scenarioId: number
): Promise<{ scenarioSummary: ScenarioSummary }> => {
    const scenarioSummary: ScenarioSummary = await getAsync<ScenarioSummary>(
        `api/clients/${entityId}/scenarios/${scenarioId}/summary`
    );
    return { scenarioSummary };
};

export const useScenariosPageQuery = (entityId: number) =>
    useSuspenseQuery({ queryKey: [CACHE_KEY, entityId], queryFn: () => getScenariosPageAsync(entityId) });

export const useCreateScenarioMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (args: { entityId: number; ownerId: number; scenarioName: string; caseId: Nullable<number> }) =>
            createScenarioAsync(args.entityId, args.ownerId, args.scenarioName, args.caseId),
        onMutate: variables => queryClient.cancelQueries({ queryKey: [CACHE_KEY, variables.entityId] }),
        onSuccess: (_, variables) => queryClient.invalidateQueries({ queryKey: [CACHE_KEY, variables.entityId] }),
    });
};

export const useScenarioSummaryQuery = (entityId: number, scenarioId: number) =>
    useSuspenseQuery({
        queryKey: [CACHE_KEY, entityId, scenarioId],
        queryFn: () => getScenarioSummaryAsync(entityId, scenarioId),
    });
