import { FC } from 'react';
import {
    IressButton,
    IressCol,
    IressInput,
    IressPanel,
    IressRow,
    IressSelect,
    LabelValue,
    IressFormField,
} from '@iress/ids-components';
import { formatDateTime } from '../../../helpers/Date/Date';
import { Client, ClientType, Case } from '@xplan-pension-switching/common';

const mapCasesOptions = (cases: Case[]): LabelValue[] => {
    if (!cases) return [];

    return cases.map(c => ({
        label: `${c.caseName} - ${formatDateTime(c.caseDate)} - ${c.clientName}`,
        value: c.id.toString(),
    }));
};

const mapClientsOptions = (clients: Client[]): LabelValue[] => {
    if (!clients) return [];

    return clients.map(c => ({
        label: c.clientType === ClientType.Client ? 'Client' : 'Partner',
        value: c.id.toString(),
    }));
};

export const CreateScenario: FC<{ cases: Case[]; clients: Client[] }> = props => {
    const { cases, clients } = props;

    return (
        <IressPanel padding={'md'} background={'default'}>
            <IressRow gutter={'md'} verticalAlign={'bottom'}>
                <IressCol span={'4'}>
                    <IressFormField
                        label={'Scenario Name'}
                        name={'scenario-name'}
                        render={controlledProps => <IressInput data-testid={'txtScenarioName'} {...controlledProps} />}
                        rules={{
                            required: 'Scenario is required',
                        }}
                    />
                </IressCol>
                <IressCol span={'2'}>
                    <IressFormField
                        label={'Owner'}
                        name={'scenario-owner'}
                        render={controlledProps => (
                            <IressSelect data-testid={'ddlOwner'} placeholder={'Select an owner'} {...controlledProps}>
                                {mapClientsOptions(clients).map(option => (
                                    <option key={String(option.value)} value={String(option.value)}>
                                        {option.label}
                                    </option>
                                ))}
                            </IressSelect>
                        )}
                        rules={{
                            required: 'Owner is required',
                        }}
                    />
                </IressCol>
                <IressCol span={'4'}>
                    <IressFormField
                        name={'scenario-case'}
                        label={'Case'}
                        render={controlledProps => (
                            <IressSelect data-testid={'ddlCase'} placeholder={'Select a case'} {...controlledProps}>
                                {mapCasesOptions(cases).map(option => (
                                    <option key={String(option.value)} value={String(option.value)}>
                                        {option.label}
                                    </option>
                                ))}
                            </IressSelect>
                        )}
                    />
                </IressCol>
                <IressCol span={'2'}>
                    <IressButton
                        id={'btnCreate'}
                        data-testid={'btnCreate'}
                        mode={'primary'}
                        type={'submit'}
                        fluid={'xxl'}
                    >
                        Create scenario
                    </IressButton>
                </IressCol>
            </IressRow>
        </IressPanel>
    );
};
