import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ReactElement } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@iress/ag-grid-theme/dist/ag-theme-iress-lite.css';
import '../../styles/TableDisplay.css';
import { generateGridOptionsFromProperties } from './GenerateGridOptionsFromProperties';
import { TableDisplayProperties } from './TableDisplayProperties';

export const TableDisplay = <T,>(properties: TableDisplayProperties<T>): ReactElement => {
    const { tableId, data, getRowClass, getRowStyle } = properties;
    const { ...gridOptions }: GridOptions<T> = generateGridOptionsFromProperties<T>(properties);

    return (
        <div
            data-testid="table-container"
            className="ag-theme-alpine ag-theme-iress-lite ag-pension-switch"
            style={{ display: 'block', width: '100%', height: 'auto' }}
        >
            <AgGridReact
                data-testid={tableId}
                gridOptions={gridOptions}
                rowData={data}
                onGridReady={(params: GridReadyEvent) => {
                    params.api.sizeColumnsToFit();
                }}
                getRowClass={getRowClass}
                getRowStyle={getRowStyle}
            />
        </div>
    );
};
