import { getAsync } from '../../helpers/Api/Api';
import { useSuspenseQuery } from '@tanstack/react-query';
import { RetirementFund } from '@xplan-pension-switching/common';

const CACHE_KEY = 'clients';

export const getRetirementFunds = async (entityId: number): Promise<{ retirementFunds: RetirementFund[] }> => {
    const retirementFunds: RetirementFund[] = await getAsync<RetirementFund[]>(
        `api/clients/${entityId}/retirement-funds`
    );
    return { retirementFunds };
};

export const useRetirementFundsQuery = (entityId: number) =>
    useSuspenseQuery({ queryKey: [CACHE_KEY, entityId], queryFn: () => getRetirementFunds(entityId) });
