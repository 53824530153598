import { IressPanel, IressStack, IressText } from '@iress/ids-components';
import { FC } from 'react';
import { scenariosTableColDefs } from './ScenariosTableColDefs';
import { TableDisplay } from '../../TableDisplay/TableDisplay';
import '../../../styles/ScenariosTable.css';
import { RowClassParams } from 'ag-grid-community';
import { Scenario } from '@xplan-pension-switching/common';

export const getScenarioRowClass = (params: RowClassParams<Scenario>) => {
    if (params?.data?.isLocked) {
        return 'locked';
    } else {
        return 'unlocked';
    }
};

export const getScenarioRowStyle = () => {
    return {
        borderBottom: 'thick solid var(--ukw-grey-extra-light)',
        borderRadius: '4px',
        overflow: 'hidden',
    };
};

export const ScenariosTable: FC<{ scenarios: Scenario[] }> = props => {
    const { scenarios } = props;
    const hasExistingScenario = scenarios && scenarios.length > 0;
    const noExistingScenarioLabel = 'No existing scenarios';

    return (
        <IressPanel padding={{ b: 'md', x: 'none' }} background={'default'}>
            {hasExistingScenario ? (
                <IressStack data-testid="scenario-table-container">
                    <TableDisplay<Scenario>
                        tableId={`scenario-table`}
                        data={scenarios}
                        columns={scenariosTableColDefs}
                        getRowClass={getScenarioRowClass}
                        getRowStyle={getScenarioRowStyle}
                    />
                </IressStack>
            ) : (
                <IressText data-testid={'no-existing-scenarios'} element={'small'} align={'center'}>
                    {noExistingScenarioLabel}
                </IressText>
            )}
        </IressPanel>
    );
};
