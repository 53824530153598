import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload, Screen } from '../../components';
import { FC } from 'react';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { useScenarioId } from '../../hooks/UseScenarioId/UseScenarioId';
import { MemoizedCurrentPlanProjectionsFormContent } from '../../components/CurrentPlanProjections/CurrentPlanProjectionsFormContent';

export const CurrentPlanProjections: FC = () => {
    const entityId = useEntityId();
    const scenarioId = useScenarioId();

    return (
        <Screen title={'Current plan projections'}>
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'300'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            >
                <MemoizedCurrentPlanProjectionsFormContent entityId={entityId} scenarioId={scenarioId} />
            </LoadingAndErrorHandler>
        </Screen>
    );
};
