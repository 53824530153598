import { FC, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { IressButton, IressIcon, IressInline, IressPanel } from '@iress/ids-components';
import { useNavigate } from 'react-router-dom';
import { useEntityId } from '../../../hooks/UseEntityId/UseEntityId';
import { Scenario } from '@xplan-pension-switching/common';

export const ScenarioActions: FC<ICellRendererParams<Scenario>> = params => {
    const entityId = useEntityId();
    const navigate = useNavigate();
    const scenario: Scenario = params.data as Scenario;
    const [scenarioLock] = useState<boolean>(scenario.isLocked);

    const handleScenarioEdit = () => {
        navigate({
            pathname: `/current-plans`,
            search: `?entityid=${entityId}&scenarioId=${scenario.id}`,
        });
    };

    return (
        <IressPanel background={'transparent'} padding={'none'}>
            <IressInline gutter={'none'}>
                <IressButton data-testid={`scenario-lock-button-${scenario.id}`} mode={'tertiary'}>
                    <IressIcon
                        data-testid={`scenario-lock-icon-${scenario.id}`}
                        name={!scenarioLock ? 'unlock' : 'lock'}
                    />
                </IressButton>
                {!scenarioLock ? (
                    <IressButton mode={'tertiary'} onClick={handleScenarioEdit}>
                        <IressIcon data-testid={`scenario-edit-icon-${scenario.id}`} name={'edit'} />
                    </IressButton>
                ) : null}
            </IressInline>
        </IressPanel>
    );
};
