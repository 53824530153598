import { FC, ReactNode } from 'react';
import { IressButton, IressInline, IressTable, IressText, TableColumn } from '@iress/ids-components';
import '../../styles/CurrentPlanProjectionsTable.css';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CurrentPlanProjection } from '@xplan-pension-switching/common';

export interface CurrentPlanProjectionsTableProps {
    entityId: number;
    scenarioId: number;
    currentPlanProjections: CurrentPlanProjection[];
}

// eslint-disable-next-line react/display-name
const planCellRenderer =
    (entityId: number, scenarioId: number, navigate: NavigateFunction) =>
    // eslint-disable-next-line react/display-name
    (value: string, row?: CurrentPlanProjection | undefined): ReactNode => (
        <IressButton
            type={'button'}
            mode={'link'}
            onClick={() => navigate(`./details?entityid=${entityId}&scenarioId=${scenarioId}&projectionId=${row?.id}`)}
        >
            {value}
        </IressButton>
    );

const statusCellRenderer = (value: boolean): ReactNode =>
    value ? (
        <IressInline gutter={'sm'}>
            <div className={'fa-sharp fa-solid fa-check success'} title={'Updated / reviewed'}></div>
            <IressText>Updated / reviewed</IressText>
        </IressInline>
    ) : (
        <IressInline gutter={'sm'}>
            <div className={'fa-solid fa-ban danger'} title={'Must be reviewed'}></div>
            <IressText>Must be reviewed</IressText>
        </IressInline>
    );

export const CurrentPlanProjectionsTable: FC<CurrentPlanProjectionsTableProps> = props => {
    const { currentPlanProjections, entityId, scenarioId } = props;
    const navigate = useNavigate();
    const columnDefs: TableColumn<CurrentPlanProjection>[] = [
        {
            key: 'name',
            label: 'Plan',
            divider: false,
            width: '50%',
            format: planCellRenderer(entityId, scenarioId, navigate),
        },
        {
            key: 'planValue',
            label: 'Current value (GBP)',
            divider: false,
            align: 'right',
            width: '25%',
            format: 'currency',
            currencyCode: '',
        },
        {
            key: 'isCompleted',
            label: 'Status',
            divider: false,
            width: '25%',
            format: statusCellRenderer,
        },
    ];

    return (
        <IressTable
            className={'table'}
            caption={'Selected plans'}
            hiddenCaption
            rows={currentPlanProjections}
            columns={columnDefs}
            empty={'No current plan projections available'}
        />
    );
};
