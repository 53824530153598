import { IressButton } from '@iress/ids-components';
import { FC, ReactNode } from 'react';

import { PageErrorAlert } from '../PageErrorAlert/PageErrorAlert';

export const PageErrorWithReload: FC<{ heading?: string; message?: string }> = ({
    heading = 'An error has occurred',
    message = "We couldn't load your data. Try again.",
}): ReactNode => (
    <PageErrorAlert heading={heading} testId="page-details-error">
        <>
            <p>{message}</p>
            <IressButton
                slot={'append'}
                onClick={() => {
                    window.location.reload();
                }}
            >
                Try again
            </IressButton>
        </>
    </PageErrorAlert>
);
