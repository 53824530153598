import { FC } from 'react';
import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload } from '../../components';
import { IressForm } from '@iress/ids-components';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { useToasterUpdater } from '../../hooks/UseToasterUpdater/UseToasterUpdater';
import { MemoizedScenarioFormContent } from '../../components/Scenario/ScenarioFormContent';

export interface ScenarioValues {
    'scenario-name': string;
    'scenario-owner': string;
    'scenario-case': string;
}

export const ScenarioForm = IressForm<ScenarioValues>;

export const Scenarios: FC = () => {
    useToasterUpdater();
    const entityId = useEntityId();

    return (
        <div data-testid={'scenarios-screen'}>
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'450'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            >
                <MemoizedScenarioFormContent entityId={entityId} />
            </LoadingAndErrorHandler>
        </div>
    );
};
