import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { Nullable, ToasterState, ToasterStatus } from '@xplan-pension-switching/common';

export const initialState: ToasterState = {
    toasterData: null,
};

const toasterSlice = createSlice<
    ToasterState,
    SliceCaseReducers<ToasterState>,
    'toaster-state',
    SliceSelectors<ToasterState>
>({
    name: 'toaster-state',
    initialState,
    reducers: {
        setToaster: (
            state: ToasterState,
            action: {
                payload: Nullable<{
                    status: ToasterStatus;
                    heading: string;
                    message: string;
                }>;
            }
        ) => {
            state.toasterData = action.payload;
        },
    },
});
export const { setToaster } = toasterSlice.actions;
export const toasterReducer = toasterSlice.reducer;
export const toasterInitialState = initialState;
