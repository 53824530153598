import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/store';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../redux/Navigation/NavigationReducer';
import { FormRef } from '@iress/ids-components';
import { GenericForm, Nullable, Page } from '@xplan-pension-switching/common';

export const usePageNavigation = (
    formReference: MutableRefObject<Nullable<FormRef<GenericForm>>>
): {
    pages: Page[];
    search: string;
    currentPage: Nullable<Page>;
    nextPage: Nullable<Page>;
    previousPage: Nullable<Page>;
    onFormSubmit: () => void;
} => {
    const { pages, currentPage, nextPage, previousPage } = useAppSelector(state => state.navigation);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (pages.length < 1) return;

        const currentPageKey = location.pathname
            .split('/')
            .filter(index => index !== '')
            .at(-1);

        const page = pages.find(page => page.key === currentPageKey);
        if (page && currentPageKey !== '') {
            dispatch(setCurrentPage(page));
        }
    }, [pages, currentPage, dispatch, location.pathname]);

    const dispatchForm = useCallback((): void => {
        (async (): Promise<void> => {
            await formReference.current?.submit();
        })();
    }, [formReference]);

    const onFormSubmit = useCallback((): void => {
        dispatchForm();
    }, [dispatchForm]);

    return {
        pages,
        search: location.search,
        currentPage,
        nextPage,
        previousPage,
        onFormSubmit,
    };
};
