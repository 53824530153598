import { FC, memo, useRef, useState } from 'react';
import { useScenariosPageQuery } from '../../services/ScenariosService/ScenariosService';
import { FormRef, IressPanel, IressStack, IressText } from '@iress/ids-components';
import { CreateScenario } from './CreateScenario/CreateScenario';
import { ScenarioHeader } from './ScenarioHeader';
import { ScenariosTable } from './ScenarioTable/ScenariosTable';
import { ScenarioForm, ScenarioValues } from '../../pages/Scenarios/Scenarios';
import { ScenarioModal } from './ScenarioModal/ScenarioModal';
import { CreateScenarioRequest } from '@xplan-pension-switching/common';

const hasValidRequest = (request: CreateScenarioRequest | undefined | null): boolean => {
    return !!(request && request.scenarioName && request.ownerId);
};

const ScenarioFormContent: FC<{ entityId: number }> = props => {
    const formRef = useRef<FormRef<ScenarioValues>>(null);
    const { entityId } = props;
    const { data } = useScenariosPageQuery(entityId);
    const { cases, scenarios, clients } = data.scenariosPage;
    const [showModal, setShowModal] = useState(false);
    const [request, setRequest] = useState<CreateScenarioRequest | null>(null);
    const modalRef = useRef(null);

    const onSubmit = (values: ScenarioValues) => {
        const toUpdate: CreateScenarioRequest = {
            ownerId: Number(values['scenario-owner']),
            caseId: values['scenario-case'] ? Number(values['scenario-case']) : null,
            scenarioName: values['scenario-name'],
            retirementFundIds: [],
        };

        if (!toUpdate.scenarioName || !toUpdate.ownerId) return;

        setShowModal(true);
        setRequest(toUpdate);
    };

    return (
        <>
            <div>
                <ScenarioForm onSubmit={onSubmit} ref={formRef} mode={'onBlur'} data-testid={'scenarios-form'}>
                    <ScenarioHeader clients={clients} />
                    <IressStack gutter={'md'}>
                        <IressPanel padding={'none'} background={'transparent'}>
                            <IressText element={'h2'}>Create a scenario</IressText>
                            <CreateScenario cases={cases} clients={clients} />
                        </IressPanel>
                        <IressPanel padding={{ t: 'md', x: 'none' }} background={'transparent'}>
                            <IressText element={'h2'}>Existing scenarios</IressText>
                            <ScenariosTable scenarios={scenarios} />
                        </IressPanel>
                    </IressStack>
                </ScenarioForm>
            </div>
            <div ref={modalRef}>
                {showModal && hasValidRequest(request) && (
                    <ScenarioModal request={request!} show={showModal} setShow={setShowModal} container={modalRef} />
                )}
            </div>
        </>
    );
};

export const MemoizedScenarioFormContent = memo(ScenarioFormContent);
