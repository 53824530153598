import { FC } from 'react';
import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload, Screen } from '../../components';
import { MemoizedCurrentPlanProjectionDetailsFormContent } from '../../components/CurrentPlanProjectionDetails/CurrentPlanProjectionDetailsFormContent';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { useScenarioId } from '../../hooks/UseScenarioId/UseScenarioId';
import { useProjectionId } from '../../hooks/UseProjectionId/UseProjectionId';

export const CurrentPlanProjectionDetails: FC = () => {
    const entityId = useEntityId();
    const scenarioId = useScenarioId();
    const projectionId = useProjectionId();

    return (
        <Screen title={'Current Plan Projection Details'}>
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'450'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            >
                <MemoizedCurrentPlanProjectionDetailsFormContent
                    entityId={entityId}
                    scenarioId={scenarioId}
                    projectionId={projectionId}
                />
            </LoadingAndErrorHandler>
        </Screen>
    );
};
