import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { toasterReducer } from './Toaster/ToasterReducer';
import { navigationReducer } from './Navigation/NavigationReducer';

export const appStore = configureStore({
    reducer: {
        toaster: toasterReducer,
        navigation: navigationReducer,
    },
});

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
export type AppStore = typeof appStore;
export const useAppDispatch: () => AppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
