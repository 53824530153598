import { CurrentPlanProjectionsPage } from '@xplan-pension-switching/common';
import { getAsync } from '../../helpers/Api/Api';
import { useSuspenseQuery } from '@tanstack/react-query';

const CACHE_KEY = 'current-plan-projections';

export const getCurrentPlanProjectionsPageAsync = async (
    entityId: number,
    scenarioId: number
): Promise<{ currentPlanProjectionsPage: CurrentPlanProjectionsPage }> => {
    const currentPlanProjectionsPage: CurrentPlanProjectionsPage = await getAsync<CurrentPlanProjectionsPage>(
        `api/clients/${entityId}/scenarios/${scenarioId}/current-plan-projections`
    );
    return { currentPlanProjectionsPage };
};

export const useCurrentPlanProjectionsPageQuery = (entityId: number, scenarioId: number) =>
    useSuspenseQuery({
        queryKey: [CACHE_KEY, entityId, scenarioId],
        queryFn: () => getCurrentPlanProjectionsPageAsync(entityId, scenarioId),
    });
