import { FC, useRef } from 'react';
import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload } from '../../components';
import { FormRef, IressForm } from '@iress/ids-components';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { useToasterUpdater } from '../../hooks/UseToasterUpdater/UseToasterUpdater';
import { MemoizedScenarioFormContent } from '../../components/Scenario/ScenarioFormContent';
import { useCreateScenarioMutation } from '../../services/ScenariosService/ScenariosService';
import { useNavigate } from 'react-router-dom';

export interface ScenarioValues {
    'scenario-name': string;
    'scenario-owner': string;
    'scenario-case': string;
}

export const ScenarioForm = IressForm<ScenarioValues>;

export const Scenarios: FC = () => {
    useToasterUpdater();
    const navigate = useNavigate();
    const entityId = useEntityId();
    const formRef = useRef<FormRef<ScenarioValues>>(null);
    const mutation = useCreateScenarioMutation();

    const onSubmit = async (values: ScenarioValues) => {
        const toUpdate = {
            ownerId: Number(values['scenario-owner']),
            caseId: values['scenario-case'] ? Number(values['scenario-case']) : null,
            scenarioName: values['scenario-name'],
        };

        if (!toUpdate.scenarioName || !toUpdate.ownerId) return;

        await mutation.mutateAsync({ entityId, ...toUpdate }).then(result => {
            navigate({
                pathname: `/current-plans`,
                search: `?entityid=${entityId}&scenarioId=${result.scenario.id}`,
            });
        });
    };

    return (
        <div data-testid={'scenarios-screen'}>
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'450'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            >
                <ScenarioForm onSubmit={onSubmit} ref={formRef} mode={'onBlur'} data-testid={'scenarios-form'}>
                    <MemoizedScenarioFormContent entityId={entityId} />
                </ScenarioForm>
            </LoadingAndErrorHandler>
        </div>
    );
};
