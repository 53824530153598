import { IressStack } from '@iress/ids-components';
import { FC, PropsWithChildren } from 'react';
import { HeaderNav } from '../HeaderNav/HeaderNav';
import { useToasterUpdater } from '../../hooks/UseToasterUpdater/UseToasterUpdater';

export const Screen: FC<
    PropsWithChildren<{
        title: string;
    }>
> = ({ children, title }) => {
    useToasterUpdater();

    return (
        <div data-testid={'screen'}>
            <HeaderNav title={title}></HeaderNav>
            <IressStack gutter={'md'}>{children}</IressStack>
        </div>
    );
};
