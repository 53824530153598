import { FC } from 'react';
import { IressPanel, IressStack } from '@iress/ids-components';
import { TableDisplay } from '../TableDisplay/TableDisplay';
import { currentPlansTableColDefs } from './CurrentPlansTableColDefs';
import '../../styles/CurrentPlansTable.css';
import { CurrentPlan } from '@xplan-pension-switching/common';

export const getCurrentPlansTableRowClass = () => 'current-plans-row';

export const CurrentPlansTable: FC<{ currentPlans: CurrentPlan[] }> = props => {
    const { currentPlans } = props;

    return (
        <IressPanel padding={'none'} background={'default'}>
            <IressStack data-testid={'current-plans-table-container'}>
                <TableDisplay<CurrentPlan>
                    tableId={`current-plans-table`}
                    data={currentPlans}
                    columns={currentPlansTableColDefs}
                    getRowClass={getCurrentPlansTableRowClass}
                />
            </IressStack>
        </IressPanel>
    );
};
