import { ColDef, GridOptions } from 'ag-grid-community';

import { TableDisplayProperties } from './TableDisplayProperties';

const getColumnDefs = <T>(columns: Array<ColDef<T>>): Array<ColDef<T>> =>
    columns.map((col: ColDef<T>) => {
        return col;
    });

export const generateGridOptionsFromProperties = <T>(properties: TableDisplayProperties<T>): GridOptions<T> => {
    const { data, columns } = properties;
    const columnDefs = getColumnDefs(columns);

    return {
        columnDefs,
        autoSizeStrategy: {
            type: 'fitGridWidth',
            defaultMinWidth: 198,
        },
        rowData: data,
        rowHeight: 34,
        domLayout: 'autoHeight',
        unSortIcon: true,
        suppressHorizontalScroll: true,
    };
};
