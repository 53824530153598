export const useProjectionId = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectionId = searchParams.get('projectionId');
    if (!projectionId) return 0;

    const projectionIdNum = Number(projectionId);
    if (projectionIdNum < 1) return 0;

    return projectionIdNum;
};
