import { FC, memo, useRef } from 'react';
import { CurrentValues } from '../CurrentValues/CurrentValues';
import { FormRef, IressForm, IressText } from '@iress/ids-components';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { useScenarioId } from '../../hooks/UseScenarioId/UseScenarioId';
import { useProjectionId } from '../../hooks/UseProjectionId/UseProjectionId';
import { CurrentPlanProjectionDetailsValues } from '@xplan-pension-switching/common';
import { useCurrentPlanProjectionDetailsPageQuery } from '../../services/CurrentPlanProjectionDetailsService/CurrentPlanProjectionDetailsService';

export const CurrentPlanProjectionDetailsForm = IressForm<CurrentPlanProjectionDetailsValues>;

const CurrentPlanProjectionDetailsFormContent: FC = () => {
    const entityId = useEntityId();
    const scenarioId = useScenarioId();
    const currentPlanId = useProjectionId();
    const formRef = useRef<FormRef<CurrentPlanProjectionDetailsValues>>(null);
    const { data } = useCurrentPlanProjectionDetailsPageQuery(entityId, scenarioId, currentPlanId);
    const { currentValues } = data.currentPlanProjectionDetailsPage.details;

    const formDefaultValues: CurrentPlanProjectionDetailsValues = {
        ...currentValues,
    };

    const onSubmit = async () => {};

    return (
        <CurrentPlanProjectionDetailsForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            ref={formRef}
            mode={'onBlur'}
            data-testid={'scenarios-form'}
        >
            <IressText element={'h2'} variant={'bold'}>
                {data.currentPlanProjectionDetailsPage.name}
            </IressText>

            <CurrentValues></CurrentValues>
        </CurrentPlanProjectionDetailsForm>
    );
};

export const MemoizedCurrentPlanProjectionDetailsFormContent = memo(CurrentPlanProjectionDetailsFormContent);
