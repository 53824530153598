import { PropsWithChildren, ReactElement, Suspense } from 'react';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export const LoadingAndErrorHandler = (
    properties: PropsWithChildren<{ LoadingComponentFallback: ReactElement; ErrorComponentFallback: ReactElement }>
): ReactElement => {
    const { children, LoadingComponentFallback, ErrorComponentFallback } = properties;
    const LoadingFallbackComponent = (): ReactElement => LoadingComponentFallback;
    const ErrorFallbackComponent = (): ReactElement => ErrorComponentFallback;
    return (
        <Suspense fallback={<LoadingFallbackComponent />}>
            <ErrorBoundary fallback={<ErrorFallbackComponent />}>{children}</ErrorBoundary>
        </Suspense>
    );
};
