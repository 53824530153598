import { ValueFormatterParams } from 'ag-grid-community';
import { formatDateTime } from '../../../helpers/Date/Date';
import { Scenario } from '@xplan-pension-switching/common';

export const caseFormatter = (params: ValueFormatterParams): string => {
    const scenario: Scenario = params.data as Scenario;
    return scenario?.caseName ?? '';
};

export const reportFormatter = (params: ValueFormatterParams): string => {
    const scenario: Scenario = params.data as Scenario;
    return `View (${scenario && scenario.reports ? scenario.reports.length : 0})`;
};

export const dateTimeFormatter = (user: string, date: string): string => {
    return `${user} ${formatDateTime(date)}`;
};
