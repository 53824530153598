import { FC } from 'react';
import { useScenarioId } from '../../hooks/UseScenarioId/UseScenarioId';
import { useEntityId } from '../../hooks/UseEntityId/UseEntityId';
import { LoadingAndErrorHandler } from '../LoadingAndErrorHandler/LoadingAndErrorHandler';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import { PageErrorWithReload } from '../PageErrorWithReload/PageErrorWithReload';
import { HeaderNavContent } from './HeaderNavContent';
import { IressPanel } from '@iress/ids-components';

export const HeaderNav: FC<{ title: string }> = props => {
    const { title } = props;
    const scenarioId = useScenarioId();
    const entityId = useEntityId();

    return (
        <IressPanel background={'transparent'} padding={{ t: 'md', x: 'none' }} data-testid={'header-nav'}>
            {!!entityId && !!scenarioId ? (
                <LoadingAndErrorHandler
                    LoadingComponentFallback={<LoadingPlaceholder height={'75'} />}
                    ErrorComponentFallback={<PageErrorWithReload />}
                >
                    <HeaderNavContent title={title} entityId={entityId} scenarioId={scenarioId}></HeaderNavContent>
                </LoadingAndErrorHandler>
            ) : null}
        </IressPanel>
    );
};
